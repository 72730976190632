@use '../../theme/colors' as *;
@use '../../theme/spacing' as *;
@use '../../theme/border' as *;
@use '../../theme/mixins/background-text-color' as *;

.chip {
  display: inline-block;
  padding: $spacing-3xs $spacing-xxs;
  border-radius: $border-radius-s;
}

.clickable {
  cursor: pointer;
}

@each $color-name, $color-value in $colors {
  .bg-#{'' + $color-name} {
    @include background-text-color($color-value);
  }
}
